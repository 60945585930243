/* eslint-disable react/no-unescaped-entities */
import { useResponsive } from "components/hooks/useResponsive";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { Tooltip } from "react-tippy";
import { checkManufacturerOrMarketerName, formatAmount } from "util/common";
import { COUNTRIES } from "util/countryListIcons";
import { ProductTypeUI, VARIANT } from "./ProductTypeUI";
import { listProductTypes } from "util/types";
import { GiftIcon } from "@heroicons/react/outline";
import moment from "moment";
import { isNewInStore, isComingSoon } from "util/date";
import ProductImage from "components/ui/ProductImage";
import { GENETIC_MATCH } from "util/types";
import { ProductCornerBadge, ProductOutOfStockBadge } from "./ProductBadges";

export function ProductsTableColumns(
	props,
	showCatalogPriceOverride = false,
	isStoreProducts = false,
	isWithGiftIcon = false,
	showStrainOrigin = false,
) {
	const screen = useResponsive();
	const { height, width } = useWindowDimensions();
	const WBO = {
		"2xs": 235,
		cbd: 365,
		xs: 475,
		sm: 640,
		md: 768,
		lg: 1024,
		xl: 1280,
		"2xl": 1536,
		"3xl": 1920,
	};

	const findCountry = (country, key) => {
		const c = COUNTRIES.find(c => c.code === country);
		if (!!c?.[key]) {
			return c?.[key];
		} else {
			return "";
		}
	};

	const getProductSubtitle = product => {
		const {
			manufacturer_series_heb_name: brand,
			marketer_heb_name: marketer,
			manufacturer_heb_name: manufacturer,
			series_heb_name: series,
		} = product;

		const unknown = "לא ידוע";

		// Create an array of available attributes in the order of hierarchy
		const attributes = [brand, manufacturer, marketer, series].filter(Boolean);

		// Initialize an array to hold unique attributes
		const uniqueAttributes = [];

		// Iterate over attributes and add to uniqueAttributes if not a duplicate
		attributes.forEach(attr => {
			if (!uniqueAttributes.includes(attr)) {
				uniqueAttributes.push(attr);
			}
		});

		// If there are at least two unique attributes, join the first two with a separator
		if (uniqueAttributes.length >= 2) {
			return `${uniqueAttributes[0]} · ${uniqueAttributes[1]}`;
		}

		// If only one unique attribute exists, return it
		if (uniqueAttributes.length === 1) {
			return uniqueAttributes[0];
		}

		// If no attributes exist, return unknown
		return unknown;
	};

	const checkStorePriceOnDiscount = (
		storePrice,
		catalogPrice,
		showCatalogPriceOverride,
		row,
	) => {
		if ((catalogPrice === 0 && !storePrice) || (!storePrice && !catalogPrice)) {
			return <>לא ידוע</>;
		}
		if (!!row?.i_a_f_s && catalogPrice) {
			return <span>₪{formatAmount(catalogPrice)}</span>;
		}
		if (!storePrice && !!catalogPrice) {
			return <> ₪{formatAmount(catalogPrice)}</>;
		}

		if (storePrice < catalogPrice && !!showCatalogPriceOverride) {
			return (
				<span>
					<span className="text-green-600 leading-none block font-semibold">
						{" "}
						₪{formatAmount(storePrice)}
					</span>
					<span className=" block text-sm line-through">
						{" "}
						₪{formatAmount(catalogPrice)}
					</span>
				</span>
			);
		}
		return <> ₪{formatAmount(storePrice)}</>;
	};

	return [
		{
			Header: <div className="p-1">שם</div>,
			accessor: "heb_name",
			id: "heb_name",
			name: "product_heb_name",
			Cell: props => {
				return (
					<div className="flex py-1 relative overflow-hidden">
						<div className="flex flex-col justify-start pl-1">
							<div className="flex gap-1">
								{!props.row.original.store_add_datetime ? (
									isComingSoon(props.row.original.created_at) ? (
										<ProductCornerBadge color="bg-[#0F85D7]" text="בקרוב" />
									) : null
								) : !props.row.original.in_stock ? null : isNewInStore(
										props.row.original.store_add_datetime,
								  ) ? (
									<ProductCornerBadge color="bg-green-800" text="חדש!" />
								) : null}
								<span className="relative">
									{!props.row.original.in_stock &&
										(props.row.original.store_add_datetime ||
											!isComingSoon(props.row.original.created_at)) && (
											<ProductOutOfStockBadge />
										)}
									<ProductImage
										src={props.row.original?.main_img_200_url}
										className="xs:w-[40px] xs:h-[40px] w-[60px] h-[60px] max-w-none"
										product={props.row.original}
									/>
								</span>

								<div className="flex flex-col justify-around">
									<div className="text-base sm:text-sm text-gray-900 font-medium w-[120px] leading-4">
										{props.row.original?.heb_name}
									</div>
									{width > WBO["md"] && (
										<span className="text-base sm:text-sm text-gray-500 font-medium w-[120px]">
											{props.row.original?.eng_name}
										</span>
									)}

									<div className="flex flex-col mb-1">
										{width < WBO["md"] && (
											<div className="text-gray-400 text-sm text-right truncate max-w-[170px]">
												{getProductSubtitle(props.row.original)}
											</div>
										)}
										{props.row.original?.is_discount_exists &&
											isWithGiftIcon && (
												<span className="flex">
													<GiftIcon className="w-3.5 text-red-500 cursor-pointer hover:text-pink-600" />
													<span className="text-xs font-semibold leading-none text-red-500">
														{props.row.original?.discount_title}
													</span>
												</span>
											)}
									</div>
									{width < WBO["xs"] && (
										<div className="flex justify-start gap-0.5 w-full">
											<ProductTypeUI
												productType={listProductTypes.find(productType => {
													return (
														productType.label ===
														props.row.original.product_type
													);
												})}
												variant={VARIANT.SMALLTEXT}
											/>
											<ProductTypeUI
												productType={listProductTypes.find(productType => {
													return (
														productType.label === props.row.original.category
													);
												})}
												variant={VARIANT.SMALLTEXT}
											/>
											<ProductTypeUI
												productType={listProductTypes.find(productType => {
													return (
														productType.label === props.row.original.family
													);
												})}
												variant={VARIANT.SMALLTEXT}
											/>
										</div>
									)}
								</div>
							</div>

							<div>
								{width > WBO["xs"] && width < WBO["md"] && (
									<div className="flex justify-start gap-0.5 w-full">
										<ProductTypeUI
											productType={listProductTypes.find(productType => {
												return (
													productType.label === props.row.original.product_type
												);
											})}
											variant={VARIANT.SMALLTEXT}
										/>
										<ProductTypeUI
											productType={listProductTypes.find(productType => {
												return (
													productType.label === props.row.original.category
												);
											})}
											variant={VARIANT.SMALLTEXT}
										/>
										<ProductTypeUI
											productType={listProductTypes.find(productType => {
												return productType.label === props.row.original.family;
											})}
											variant={VARIANT.SMALLTEXT}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				);
			},
		},
		{
			Header: <div>אפיון</div>,
			hide: width < WBO["md"],
			name: "product_type",
			id: "product_type",
			accessor: undefined,
			Cell: props => (
				<div className="flex justify-center gap-0.5">
					<ProductTypeUI
						productType={listProductTypes.find(productType => {
							return productType.label === props.row.original.product_type;
						})}
						variant={VARIANT.SMALLTEXT}
					/>
					<ProductTypeUI
						productType={listProductTypes.find(productType => {
							return productType.label === props.row.original.category;
						})}
						variant={VARIANT.SMALLTEXT}
					/>
					<ProductTypeUI
						productType={listProductTypes.find(productType => {
							return productType.label === props.row.original.family;
						})}
						variant={VARIANT.SMALLTEXT}
					/>
				</div>
			),
		},
		{
			Header: <div className="mt-0.5">שיוך גנטי</div>,
			name: "genetic_match",
			id: "genetic_match",
			accessor: "genetic_match",
			hide: !showStrainOrigin,
			Cell: props => (
				<>
					{!!props?.row?.original?.genetic_match && (
						<div className="text-center text-jane-800 text-sm font-semibold leading-[12px] sm:leading-[16px]">
							{GENETIC_MATCH[props.row.original.genetic_match]}
						</div>
					)}
				</>
			),
		},
		{
			Header: <div className="mt-0.5">משווק</div>,
			width: width === WBO["2xs"] ? 10 : 200,
			hide: !screen.is("xl"),
			name: "marketer_heb_name_new_ui",
			id: "marketer__heb_name",
			accessor: "marketer__heb_name",
			Cell: props => (
				<>
					{checkManufacturerOrMarketerName(
						props?.row?.original?.marketer_heb_name,
					)}
				</>
			),
		},
		{
			Header: <div className="mt-0.5">מגדל</div>,
			width: width === WBO["2xs"] ? 10 : 300,
			name: "manufacturer_heb_name_new_ui",
			id: "manufacturer__heb_name",
			accessor: "manufacturer__heb_name",
			hide: !screen.is("lg"),
			Cell: props => (
				<>
					{checkManufacturerOrMarketerName(
						props?.row?.original?.manufacturer_heb_name,
					)}
				</>
			),
		},
		{
			Header: <div className="mt-0.5">סדרה</div>,
			width: width === WBO["2xs"] ? 10 : 200,
			id: "series__heb_name",
			name: "series",
			accessor: "series__heb_name",
			hide: !screen.is("xl"),
			Cell: props => (
				<>
					{!!props?.row?.original?.series_heb_name ? (
						<div className="text-jane-800 text-center text-sm font-semibold leading-[12px] xs:leading-[12px] sm:leading-[16px] xs:ml-4  break-words">
							{props?.row?.original?.series_heb_name}
						</div>
					) : null}
				</>
			),
		},
		{
			Header: <div className="mt-0.5">מותג</div>,
			// width: width === WBO["2xs"] ? 10 : 200,
			id: "manufacturer_series__heb_name",
			name: "manufacturer_series",
			accessor: "manufacturer_series__heb_name",
			hide: width < WBO["md"],
			Cell: props => (
				<>
					{!!props?.row?.original?.manufacturer_series_heb_name ? (
						<div className="text-jane-800 text-center text-sm font-semibold leading-[12px] xs:leading-[12px] sm:leading-[16px] xs:ml-4  break-words">
							{props?.row?.original?.manufacturer_series_heb_name}
						</div>
					) : null}
				</>
			),
		},
		{
			Header: <div className="mt-0.5">ארץ ייצור</div>,
			width: width === WBO["2xs"] ? 10 : 200,
			id: "origin_country",
			name: "origin_country",
			accessor: "origin_country",
			hide: !screen.is("xl"),
			Cell: props => (
				<>
					{!!props?.row?.original?.origin_country ? (
						<div className="flex items-center justify-center text-jane-800 m-auto text-center text-sm font-semibold">
							<span className="block">
								{findCountry(props?.row?.original?.origin_country, "heb_name")}
							</span>
							<Tooltip
								title={findCountry(
									props?.row?.original?.origin_country,
									"flag",
								)}
								placement="top"
								arrow
							>
								<img
									className="w-4 h-4 mx-1"
									alt="ארץ ייצור"
									title="ארץ ייצור"
									src={findCountry(
										props?.row?.original?.origin_country,
										"flagSVG",
									)}
								/>
							</Tooltip>
						</div>
					) : null}
				</>
			),
		},
		{
			Header: <div className="mt-0.5">סמלילים</div>,
			id: "symbols",
			name: "symbols",
			hide: isStoreProducts || !screen.is("xl"),
			Cell: props => (
				<>
					{!!props?.row?.original?.symbols ? (
						<div className="text-jane-800 xl:mx-1">
							<div className="flex items-center justify-center w-[100px]">
								{!!props?.row?.original?.symbols &&
									props?.row?.original?.symbols?.length < 10 &&
									props?.row?.original?.symbols
										.sort((a, b) => a.description.localeCompare(b.description))
										.map((parent, index) => {
											return (
												<Tooltip
													key={index}
													title={parent.description}
													placement="top"
													arrow
												>
													<img
														className="w-4 h-4 m-[1px] rounded"
														src={parent?.img_url}
														alt={parent.description}
														title={parent.description}
													/>
												</Tooltip>
											);
										})}
							</div>
						</div>
					) : null}
				</>
			),
		},
		{
			Header: <div className="mt-0.5">תוקף</div>,
			name: "store_product_date",
			id: "store_product_date",
			accessor: "store_product_date",
			hide: width < WBO["xs"] || !isStoreProducts,
			Cell: props => {
				const getExpirationDate = () => {
					if (!!props?.row?.original?.batch?.expiration_date) {
						return moment(props.row.original.batch.expiration_date).format(
							"MM/YY",
						);
					} else if (!!props?.row?.original?.store_product_date) {
						return moment(props.row.original.store_product_date).format(
							"MM/YY",
						);
					} else {
						return false;
					}
				};
				return (
					!!isStoreProducts && (
						<div className="text-center text-jane-800 text-sm font-semibold leading-[12px] sm:leading-[16px]">
							{getExpirationDate()}
						</div>
					)
				);
			},
		},
		{
			Header: <div className="mt-0.5">אצווה</div>,
			name: "batch_id",
			id: "batch_id",
			accessor: "batch_id",
			width: width === WBO["2xs"] ? 10 : 20,
			hide: width < WBO["sm"] || !isStoreProducts,
			Cell: props => (
				<>
					{!!props?.row?.original?.batch?.batch_id && (
						<div className="text-center text-jane-800 text-sm font-semibold leading-[12px] sm:leading-[16px]">
							{props.row.original.batch.batch_id}
						</div>
					)}
				</>
			),
		},
		{
			Header: <div className="mt-0.5">מחיר</div>,
			name: "price_new_ui",
			id: isStoreProducts ? "store_price" : "from_price",
			accessor: isStoreProducts ? "store_price" : "from_price",
			width:
				width === WBO["2xs"] || width === WBO["xs"] || width === WBO["sm"]
					? 10
					: 200,
			hide: showStrainOrigin && width < WBO["xs"],
			Cell: props => (
				<div className="text-jane-800 font-semibold sm:text-sm break-words pl-[1px]">
					{!!props?.row?.original?.from_price &&
					!!props?.row?.original?.to_price &&
					props?.row?.original?.from_price !==
						props?.row?.original?.to_price ? (
						<div
							className="text-center leading-[12px] xs:leading-[12px] sm:leading-[14px]"
							dir="ltr"
						>
							₪{formatAmount(props?.row?.original?.from_price)}- ₪
							{formatAmount(props?.row?.original?.to_price)}
						</div>
					) : (
						<div className="text-center pr-1" dir="ltr">
							{checkStorePriceOnDiscount(
								isStoreProducts
									? props?.row?.original?.store_price
									: props?.row?.original?.from_price ||
											props?.row?.original?.to_price ||
											props?.row?.original?.min_price ||
											props?.row?.original?.store_price,
								props?.row?.original?.catalog_price,
								showCatalogPriceOverride,
								props?.row?.original,
							)}
						</div>
					)}
				</div>
			),
		},
	].filter(c => !!!c.hide);
}
